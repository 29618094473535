import React from 'react'
import smImage from '../../assets/images/hero-ceramtec-sm.png'
import mdImage from '../../assets/images/hero-ceramtec-md.png'
import lgImage from '../../assets/images/hero-ceramtec.png'
import CaseStudyView from './CaseStudyView'
import logo from '../../assets/images/brands/ceramtec-logo-white.png'

const ceramtecItems = [
  { label: 'case-study:sector', value: 'case-study:ceramtec:sector' },
  { label: 'case-study:revenue', value: '€550M+' },
  { label: 'case-study:employees', value: '3,400' },
]

const CeramtecView = () => {
  return (
    <>
      <CaseStudyView
        caseStudy="ceramtec"
        title="case-study:ceramtec:title"
        buttonText="action:see-case-study"
        smImage={smImage}
        mdImage={mdImage}
        lgImage={lgImage}
        logo={logo}
        alt="ceramtec"
        items={ceramtecItems}
        percent="78%"
        percentText="case-study:ceramtec:percent-text"
        descriptionTitle="our-approach:title"
        descriptionChallenge="case-study:ceramtec:challenge:description"
        descriptionSolution="case-study:ceramtec:solution:description"
        descriptionOutcome="case-study:ceramtec:outcome:description"
      />
    </>
  )
}

export default CeramtecView
