import React from 'react'
import MainLayout from '../layouts/MainLayout'
import CeramtecView from '../views/CeramtecView'

const CeramtecPage = () => {
  return (
    <MainLayout className="ceramtec">
      <CeramtecView />
    </MainLayout>
  )
}

export default CeramtecPage
