import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'
import CeramtecPage from '../ui/pages/CeramtecPage'

const Ceramtec = props => (
  <>
    <HelmetComponent
      title={metaTags.ceramtecTitle}
      description={metaTags.ceramtec}
      page="ceramtec"
    />
    <AppWrapper {...props}>
      <CeramtecPage />
    </AppWrapper>
  </>
)

export default Ceramtec
